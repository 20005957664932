html {
    font-family: Cambria, Arial, Georgia, serif;
    background: url(/assets/images/agua.jpg) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    scroll-behavior: smooth;
}

body {
    background-color: transparent;
}

#general {
    height: auto;
    padding-top: 5px;
    padding-right: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    margin-top: 5px;
    text-align: justify;
}

#seccion {
    height: auto;
    float: center;
    background-color: white;
    opacity: 0.99;
}

#seccion p {
    font-size: medium;
    /*background:#00FFFF;*/
}

#seccion .informacion_politica_datos {
    font-size: 10px;
    margin: 0px;
    padding: 0px;
}

#main-wrapper p,
li {
    text-align: justify;
}

h1 {
    font-size: x-large;
    font-weight: bold;
    color: #FFFFFF;
    padding-top: 10px;
    padding-right: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    margin-top: 10px;
    margin-bottom: 5px;
    background: url(/assets/images/franja_nivel_1.png);
    background-size: 100% 35px;
    background-repeat: no-repeat;
    background-position: left 2px top 10px;
}

h2 {
    font-size: large;
    font-weight: bold;
    color: #002596;
    padding-top: 5px;
    padding-right: 5px;
    padding-bottom: 5px;
    padding-left: 1px;
    margin-top: 5px;
    margin-left: 1px;
    margin-bottom: 10px;
    background: url(/assets/images/franja_nivel_2.png);
    background-size: 100% 2px;
    background-repeat: no-repeat;
    background-position: left 1px bottom 1px;
}

h3 {
    font-size: large;
    color: #134779;
    margin-left: 50px;
    margin-right: 110px;
    text-indent: 50px;
    margin-bottom: 20px;
}

h4 {
    margin-left: 50px;
    margin-right: 110px;
    text-indent: 50px;
    margin-bottom: 20px;
}

img.floatleft {
    float: left;
    margin: 5px;
}

img.floatright {
    float: right;
    margin: 5px;
}

img.center {
    display: block;
    margin: 0 auto;
}

p.floatCenter {
    text-align: center;
    display:flex;
    justify-content:center;
}

.grdp {
    position: fixed;
    bottom: 10px;
    left: 0;
    right: 0;
    width: 65%;
    margin: 1% auto;
    z-index: 100000;
    border: 2px #dedddd solid;
    text-align: justify;
    font-size: 5;
  }
   
  .bcgrdp {
    cursor: pointer;
  }

a {
    color: #002596;
    text-decoration: bold;
}

a:hover {
    color: #002596;
    text-decoration: none;
}

  /* ============ desktop view ============ */
  @media all and (min-width: 990px) {
	.navbar .dropdown-menu-end{ right:0; left: auto;  }
	.navbar .nav-item .dropdown-menu{  display:block; opacity: 0;  visibility: hidden; transition:.5s; margin-top:0;  }
	.navbar .nav-item:hover .nav-link{ color: #fff;  }
	.navbar .dropdown-menu.fade-down{ top:80%; transform: rotateX(-75deg); transform-origin: 0% 0%; }
	.navbar .dropdown-menu.fade-up{ top:180%;  }
	.navbar .nav-item:hover .dropdown-menu{ transition: .3s; opacity:1; visibility:visible; top:100%; transform: rotateX(0deg); }
}
/* ============ desktop view .end// ============ */